import * as React from "react"
import Layout from "../components/Layout"
import FaqDropdownList from '../components/Faq/DropdownList'
import IFrameVideo from "../components/IFrameVideo"
import { Link } from "gatsby"
import "./pageStyles/index.scss"
import Seo from "../components/Seo"

const IndexPage = () => {
  return (
    <Layout>
      <Seo />
      <div id="page-container" style={{ overflowY: "hidden", marginTop: "-1px", width: "100%" }} className="index-animated-content et-animated-content">
        <div id="index-boc et-boc" className="index-boc et-boc">
          <div id="index-main-area et-main-area">
            <div id="main-content">
              <article id="post-19" className="post-19 page type-page status-publish hentry">
                <div className="entry-content">
                  <div className="index-l et-l index-l--post et-l--post">
                    <div className="index_builder_inner_content et_builder_inner_content index_pb_gutters3 et_pb_gutters3">
                      <div className="index_pb_section et_pb_section index_pb_section_13 et_pb_section_13 index_pb_with_background et_pb_with_background index_section_regular et_section_regular section_has_divider index_pb_bottom_divider et_pb_bottom_divider index_pb_top_divider et_pb_top_divider optml-bg-lazyloaded index_pb_section_first et_pb_section_first" data-fix-page-container="on" style={{ paddingTop: "0px" }}>
                        <div className="index_pb_row et_pb_row index_pb_row_23 et_pb_row_23 optml-bg-lazyloaded">
                          <div className="index_pb_column et_pb_column index_pb_column_4_4 et_pb_column_4_4 index_pb_column_47 et_pb_column_47  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough index-last-child et-last-child">
                            <div className="index_pb_module et_pb_module index_pb_text et_pb_text index_text_32 index_pb_text_align_center et_pb_text_align_center index_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded index_had_animation et_had_animation" data-animation-style="bounce" data-animation-repeat="" data-animation-duration="1000ms" data-animation-delay="0ms" data-animation-intensity="50%" data-animation-starting-opacity="0%" data-animation-speed-curve="ease-in-out">
                              <div className="index_pb_text_inner et_pb_text_inner">
                                <h1>Use Only One Circuit Per Day</h1>
                                <p>Protecting Every Breath&trade;</p></div>
                            </div>
                          </div>
                        </div>
                        <div className="index_pb_row et_pb_row index_pb_row_24 et_pb_row_24 index_pb_gutters2 et_pb_gutters2 optml-bg-lazyloaded">
                          <div className="index_pb_column et_pb_column index_pb_column_1_2 et_pb_column_1_2 index_pb_column_48 et_pb_column_48  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough">
                            <div className="index_pb_module et_pb_module index_pb_image et_pb_image index_pb_image_6 et_pb_image_6 optml-bg-lazyloaded index-animated et-animated index_had_animation et_had_animation" data-animation-style="zoomLeft" data-animation-repeat="" data-animation-duration="1000ms" data-animation-delay="0ms" data-animation-intensity="50%" data-animation-starting-opacity="0%" data-animation-speed-curve="ease-in-out">
                              <span className="index_pb_image_wrap et_pb_image_wrap ">
                                <img decoding="async" src="https://res.cloudinary.com/dthskrjhy/image/upload/v1629122077/Arc_Medical/five-per-tube-long.png" alt="" title="five-per-tube-long"
                                  sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 800px, 100vw" />
                              </span>
                            </div>
                          </div>
                          <div className="index_pb_column et_pb_column index_pb_column_1_2 et_pb_column_1_2 index_pb_column_49 et_pb_column_49  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough index-last-child et-last-child">
                            <div className="index_pb_module et_pb_module index_pb_text et_pb_text index_text_33 index_pb_text_align_justified et_pb_text_align_justified index_pb_bg_layout_dark et_pb_bg_layout_dark optml-bg-lazyloaded index_had_animation et_had_animation" data-animation-style="bounceRight" data-animation-repeat="" data-animation-duration="1000ms" data-animation-delay="0ms" data-animation-intensity="50%" data-animation-starting-opacity="0%" data-animation-speed-curve="ease-in-out">
                              <div className="index_pb_text_inner et_pb_text_inner">
                                <h6>ANESTHESIA Protection System</h6>
                                <h1>circuitGuard<span style={{ fontSize: '10px' }}>&reg;</span></h1>
                                <p data-testid="product-description">circuitGuard&reg; protects the anesthesia provider, patient, and equipment against contamination of the anesthesia circuit as well as protecting the patient from any contaminated anesthesia equipment. Stop throwing away 4 out of 5 circuits daily!</p></div>
                            </div>
                            <div className="single-patient-use-btn index_pb_button_module_wrapper et_pb_button_module_wrapper index_pb_button_15_wrapper et_pb_button_15_wrapper index_pb_button_alignment_left et_pb_button_alignment_left index_pb_module et_pb_module optml-bg-lazyloaded">
                              <a className="index_pb_button et_pb_button index_pb_button_15 et_pb_button_15 et_hover_enabled index_pb_bg_layout_dark et_pb_bg_layout_dark" href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629128953/Arc_Medical/History-of-Single-Patient-Use-Anesthesia-Breathing-Circuits-in-USA-2021.pdf">Read the history of single patient use anesthesia breathing circuits</a>
                            </div>
                          </div>
                        </div>
                        <div className="index_pb_row et_pb_row index_pb_row_25 et_pb_row_25 index_pb_gutters2 et_pb_gutters2 optml-bg-lazyloaded">
                          <div className="index_pb_column et_pb_column index_pb_column_1_3 et_pb_column_1_3 index_pb_column_50 et_pb_column_50  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough">
                            <div className="index_pb_module et_pb_module index_pb_blurb et_pb_blurb index_blurb_description index_pb_text_align_left et_pb_text_align_left index_pb_blurb_position_left et_pb_blurb_position_left index_pb_bg_layout_dark et_pb_bg_layout_dark optml-bg-lazyloaded index_had_animation et_had_animation" data-animation-style="fold" data-animation-repeat="" data-animation-duration="1000ms" data-animation-delay="150ms" data-animation-intensity="30%" data-animation-starting-opacity="0%" data-animation-speed-curve="ease-in-out">
                              <div className="index_pb_blurb_content et_pb_blurb_content">
                                <div className="index_pb_main_blurb_image et_pb_main_blurb_image">
                                  <span className="thumb-up-icon-et index-pb-icon et-pb-icon index_pb_animation_top et_pb_animation_top index-animated et-animated" />
                                </div>
                                <div className="index_pb_blurb_container et_pb_blurb_container">
                                  <h4 className="index_pb_module_header et_pb_module_header"><span>Easy to Use</span></h4>
                                  <div className="index_pb_blurb_description et_pb_blurb_description" data-testid="easy-to-use-description">With it's low profile and small size, simply place the circuitGuard&reg; at the wye and with a mask, anesthesia is ready to start!</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="index_pb_column et_pb_column index_pb_column_1_3 et_pb_column_1_3 index_pb_column_51 et_pb_column_51  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough">
                            <div className="index_pb_module et_pb_module index_pb_blurb et_pb_blurb index_blurb_description index_pb_text_align_left et_pb_text_align_left index_pb_blurb_position_left et_pb_blurb_position_left index_pb_bg_layout_dark et_pb_bg_layout_dark optml-bg-lazyloaded index_had_animation et_had_animation" data-animation-style="fold" data-animation-repeat="" data-animation-duration="1000ms" data-animation-delay="150ms" data-animation-intensity="30%" data-animation-starting-opacity="0%" data-animation-speed-curve="ease-in-out">
                              <div className="index_pb_blurb_content et_pb_blurb_content">
                                <div className="index_pb_main_blurb_image et_pb_main_blurb_image">
                                  <span className="checkmark-icon-et index-pb-icon et-pb-icon index_pb_animation_top et_pb_animation_top index-animated et-animated" />
                                </div>
                                <div className="index_pb_blurb_container et_pb_blurb_container">
                                  <h4 className="index_pb_module_header et_pb_module_header"><span>100% Compliance</span></h4>
                                  <div className="index_pb_blurb_description et_pb_blurb_description" data-testid="compliance-description">With the gas sampling port only on the circuitGuard&reg;, and not on the circuit itself, this leads to 100% compliance by anesthesia provider.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="index_pb_column et_pb_column index_pb_column_1_3 et_pb_column_1_3 index_pb_column_52 et_pb_column_52  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough index-last-child et-last-child">
                            <div className="index_pb_module et_pb_module index_pb_blurb et_pb_blurb index_blurb_description index_pb_text_align_left et_pb_text_align_left index_pb_blurb_position_left et_pb_blurb_position_left index_pb_bg_layout_dark et_pb_bg_layout_dark optml-bg-lazyloaded index_had_animation et_had_animation" data-animation-style="fold" data-animation-repeat="" data-animation-duration="1000ms" data-animation-delay="150ms" data-animation-intensity="30%" data-animation-starting-opacity="0%" data-animation-speed-curve="ease-in-out">
                              <div className="index_pb_blurb_content et_pb_blurb_content">
                                <div className="index_pb_main_blurb_image et_pb_main_blurb_image">
                                  <span className="dollar-icon-et index-pb-icon index_pb_animation_top et_pb_animation_top et-pb-icon index-animated et-animated" />
                                </div>
                                <div className="index_pb_blurb_container et_pb_blurb_container">
                                  <h4 className="index_pb_module_header et_pb_module_header">
                                    <span>Cost-Effective</span>
                                  </h4>
                                  <div className="index_pb_blurb_description et_pb_blurb_description" data-testid="cost-effective-description">circuitGuard&reg; reduces expense by eliminating other HMEs and filters in the circuit and also reducing the O.R. turnover times.</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="index_pb_bottom_inside_divider et_pb_bottom_inside_divider" ></div>
                      </div>
                      <div className="index_pb_section et_pb_section index_pb_section_14 et_pb_section_14 index_section_specialty et_section_specialty">
                        <div className="index_pb_row et_pb_row optml-bg-lazyloaded">
                          <div className="index_pb_column et_pb_column index_pb_column_1_2 et_pb_column_1_2 index_pb_column_53 et_pb_column_53 index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough index_pb_column_single et_pb_column_single">
                            <div className="index_pb_module et_pb_module index_pb_text et_pb_text index_text_34 index_pb_text_align_left et_pb_text_align_left index_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="index_pb_text_inner et_pb_text_inner">
                                <h6>circuitGuard<span style={{ fontSize: "10px" }}>&reg;</span> makes the difference.</h6>
                                <h1>Protecting Every Breath&trade; since 1990</h1>
                              </div>
                            </div>
                            <IFrameVideo title="What is circuitGuard?" src="https://player.vimeo.com/video/601347434?h=3679cdd852" name="fitvid0" />
                            <div className="index_pb_module et_pb_module index_pb_text et_pb_text index_pb_text_35 et_pb_text_35 index_pb_text_align_center et_pb_text_align_center index_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="index_pb_text_inner et_pb_text_inner">View why medical Professionals choose circuitGuard&reg;.</div>
                            </div>
                          </div>
                          <div className="index_pb_column et_pb_column index_pb_column_1_2 et_pb_column_1_2 index_pb_column_54 et_pb_column_54   index_pb_specialty_column et_pb_specialty_column  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough index-last-child et-last-child">
                            <div className="index_pb_row_inner et_pb_row_inner index_pb_row_inner_3 et_pb_row_inner_3">
                              <div className="index_pb_column et_pb_column index_pb_column_4_4 et_pb_column_4_4 index_pb_column_inner et_pb_column_inner index_pb_column_inner_3 et_pb_column_inner_3 index-last-child et-last-child">
                                <div className="index_pb_module et_pb_module index_pb_blurb_extended et_pb_blurb_extended index_pb_blurb_extended_6 et_pb_blurb_extended_6 index_pb_text_align_left et_pb_text_align_left optml-bg-lazyloaded">
                                  <div className="index_pb_module_inner et_pb_module_inner">
                                    <div className="index_pb_blurb_position_left et_pb_blurb_position_left classic use_circle  index_pb_bg_layout_light et_pb_bg_layout_light" data-blurb-position="left">
                                      <div className="index_pb_blurb_content et_pb_blurb_content">
                                        <div className="index_pb_main_blurb_image et_pb_main_blurb_image">
                                          <span className="life-preserver-icon-et index-pb-icon et-pb-icon index_pb_animation_top et_pb_animation_top el-icon-circle index-animated et-animated" style={{ color: "#ffffff", backgroundColor: "#5b93c1" }} />
                                        </div>
                                        <div className="index_pb_blurb_container et_pb_blurb_container">
                                          <h4 className="index_pb_module_header et_pb_module_header">CROSS CONTAMINATION PROTECTION</h4>
                                          <div className="index_pb_blurb_description et_pb_blurb_description" data-testid="cross-contamination-description">With circuitGuard&trade; at the proximal airway, bacteria and viruses have to travel through one filter into the circuit and then traverse back through a SECOND filter (on the next patient) in order for cross contamination to occur.</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="index_pb_module et_pb_module index_pb_blurb_extended et_pb_blurb_extended index_pb_blurb_extended_7 et_pb_blurb_extended_7 index_pb_text_align_left et_pb_text_align_left optml-bg-lazyloaded">
                                  <div className="index_pb_module_inner et_pb_module_inner">
                                    <div className="index_pb_blurb_position_left et_pb_blurb_position_left classic use_circle  index_pb_bg_layout_light et_pb_bg_layout_light" data-blurb-position="left">
                                      <div className="index_pb_blurb_content et_pb_blurb_content">
                                        <div className="index_pb_main_blurb_image et_pb_main_blurb_image">
                                          <span className="quadrant-icon-et index-pb-icon et-pb-icon index_pb_animation_top et_pb_animation_top el-icon-circle et-animated" style={{ color: "#ffffff", backgroundColor: "#5b93c1" }} />
                                        </div>
                                        <div className="index_pb_blurb_container et_pb_blurb_container">
                                          <h4 className="index_pb_module_header et_pb_module_header">VARIOUS CONFIGURATIONS</h4>
                                          <div className="index_pb_blurb_description et_pb_blurb_description" data-testid="various-configurations-description">circuitGuard&trade; Products are available in filter or filter/HME styles. High quality air cushion anesthesia masks may be packaged with any circuitGuard&trade; product.</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="index_pb_button_module_wrapper et_pb_button_module_wrapper index_pb_button_16_wrapper et_pb_button_16_wrapper index_pb_button_alignment_center et_pb_button_alignment_center index_pb_module et_pb_module optml-bg-lazyloaded">
                                  <Link to="/contact/" className="index_pb_button et_pb_button index_pb_button_16 et_pb_button_16 et_hover_enabled index_pb_bg_layout_dark et_pb_bg_layout_dark" data-et-has-event-already="true">Purchase circuitGuard&trade;</Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="index_pb_section et_pb_section index_pb_section_15 et_pb_section_15 index_pb_with_background et_pb_with_background index_section_regular et_section_regular section_has_divider index_pb_bottom_divider et_pb_bottom_divider index_pb_top_divider et_pb_top_divider optml-bg-lazyloaded">
                        <div className="index_pb_top_inside_divider et_pb_top_inside_divider" ></div>
                        <div className="index_pb_row et_pb_row index_pb_row_26 et_pb_row_26 optml-bg-lazyloaded">
                          <div className="index_pb_column et_pb_column index_pb_column_4_4 et_pb_column_4_4 index_pb_column_55 et_pb_column_55  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough index-last-child et-last-child">
                            <div className="index_pb_module et_pb_module index_pb_text et_pb_text index_text_36 index_pb_text_align_center et_pb_text_align_center index_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="index_pb_text_inner et_pb_text_inner"><h1>circuitGuard&reg; is Easy to Use</h1></div>
                            </div>
                          </div>
                        </div>
                        <div className="index_pb_row et_pb_row index_pb_row_27 et_pb_row_27 optml-bg-lazyloaded">
                          <div className="index_pb_column et_pb_column index_pb_column_1_2 et_pb_column_1_2 index_pb_column_56 et_pb_column_56  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough">
                            <div className="index_pb_module et_pb_module index_pb_text et_pb_text index_text_37 index_pb_text_align_left et_pb_text_align_left index_pb_bg_layout_dark et_pb_bg_layout_dark optml-bg-lazyloaded">
                              <div className="index_pb_text_inner et_pb_text_inner">
                                <p className="fa8kfyn">"circuitGuard&reg; may either be used on the patient side or on the device side of the ventilator/anesthetic device and is used as a hygienic measure alternatively to decontamination of breathing system and/or breathing gas conveying parts of the ventilator."</p>
                                <p><strong>-Reference FDA 510(k) K090738</strong></p>
                              </div>
                            </div>
                            <div className="index_pb_button_module_wrapper et_pb_button_module_wrapper index_pb_button_17_wrapper et_pb_button_17_wrapper index_pb_button_alignment_center et_pb_button_alignment_center index_pb_module et_pb_module optml-bg-lazyloaded">
                              <Link to="/contact/" className="index_pb_button et_pb_button index_pb_button_17 et_pb_button_17 et_hover_enabled index_pb_bg_layout_dark et_pb_bg_layout_dark" data-et-has-event-already="true">Purchase circuitGuard&trade;</Link>
                            </div>
                          </div>
                          <div className="index_pb_column et_pb_column index_pb_column_1_2 et_pb_column_1_2 index_pb_column_57 et_pb_column_57  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough index-last-child et-last-child">
                            <IFrameVideo title="circuitGuard Instructional Video" src="https://player.vimeo.com/video/601347299?h=902cb3960d" name="fitvid1" />
                            <div className="index_pb_module et_pb_module index_pb_text et_pb_text index_text_38 index_pb_text_align_center et_pb_text_align_center index_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="index_pb_text_inner et_pb_text_inner">View HOW to Use circuitGuard&reg;.</div>
                            </div>
                          </div>
                        </div>
                        <div className="index_pb_bottom_inside_divider et_pb_bottom_inside_divider" ></div>
                      </div>
                      <div className="index_pb_section et_pb_section index_pb_section_16 et_pb_section_16 index_section_regular et_section_regular">
                        <div className="index_pb_row et_pb_row index_pb_row_28 et_pb_row_28 optml-bg-lazyloaded">
                          <div className="index_pb_column et_pb_column index_pb_column_4_4 et_pb_column_4_4 index_pb_column_58 et_pb_column_58  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough index-last-child et-last-child">
                            <div className="index_pb_module et_pb_module index_pb_text et_pb_text index_text_39 index_pb_text_align_left et_pb_text_align_left index_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="index_pb_text_inner et_pb_text_inner"><h1>Frequently Asked Questions</h1></div>
                            </div>
                          </div>
                        </div>
                        <div className="index_pb_row et_pb_row index_pb_row_29 et_pb_row_29 optml-bg-lazyloaded">
                          <div className="index_pb_column et_pb_column index_pb_column_1_2 et_pb_column_1_2 index_pb_column_59 et_pb_column_59  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough">
                            <IFrameVideo title="circuitGuard FAQ Video" src="https://player.vimeo.com/video/601346948?h=624330a556" name="fitvid2" />
                            <div className="index_pb_module et_pb_module index_pb_text et_pb_text index_pb_text_40 et_pb_text_40 index_pb_text_align_center et_pb_text_align_center index_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="index_pb_text_inner et_pb_text_inner">Get your questions about circuitGuard&reg; answered.</div>
                            </div>
                            <div className="index_pb_module et_pb_module index_pb_image et_pb_image index_pb_image_7 et_pb_image_7 optml-bg-lazyloaded">
                              <a href="https://greenhealthapproved.org/greenhealth-approved-products">
                                <span className="index_pb_image_wrap et_pb_image_wrap ">
                                  <img decoding="async" src="https://res.cloudinary.com/dthskrjhy/image/upload/v1629122439/Arc_Medical/greenhealth-approved.png" alt=""
                                    title="greenhealth-approved"
                                    sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) and (max-width: 1280px) 1280px, (min-width: 1281px) 2138px, 100vw" />
                                </span>
                              </a>
                            </div>
                            <div className="index_pb_module et_pb_module index_pb_text et_pb_text index_pb_text_41 et_pb_text_41 index_pb_text_align_left et_pb_text_align_left index_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                              <div className="index_pb_text_inner et_pb_text_inner">
                                <p>The Greenhealth Approved distinction is given to medical suppliers who meet extensive sustainability criteria that was established specifically for this product category by leaders in the industry. The review includes data submission and questionnaire information that validates products measure up to required attributes.&nbsp;</p>
                                <p>Through many years of promoting sustainability within the medical field, this distinction holds a special place for ARC Medical. Furthermore, this distinction acts as a testament to the tireless dedication we have towards providing safe, effective, and sustainable products.</p>
                                <p>&nbsp;</p>
                              </div>
                            </div>
                          </div>
                          <div className="index_pb_column et_pb_column index_pb_column_1_2 et_pb_column_1_2 index_pb_column_60 et_pb_column_60  index_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough index-last-child et-last-child">
                            <FaqDropdownList />
                            <div className="index_pb_button_module_wrapper et_pb_button_module_wrapper index_pb_button_18_wrapper et_pb_button_18_wrapper index_pb_button_alignment_center et_pb_button_alignment_center index_pb_module et_pb_module optml-bg-lazyloaded">
                              <a className="margin-top-5 index_pb_button et_pb_button index_pb_button_18 et_pb_button_18 et_hover_enabled index_pb_bg_layout_dark et_pb_bg_layout_dark" href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629129156/Arc_Medical/New-circuitGuard-QA.pdf" target="_blank" rel="noreferrer">FAQs DOWNLOAD</a>
                            </div>
                            <div className="index_pb_button_module_wrapper et_pb_button_module_wrapper index_pb_button_19_wrapper et_pb_button_19_wrapper index_pb_button_alignment_center et_pb_button_alignment_center index_pb_module et_pb_module optml-bg-lazyloaded">
                              <a className="index_pb_button et_pb_button index_pb_button_19 et_pb_button_19 et_hover_enabled index_pb_bg_layout_dark et_pb_bg_layout_dark" href="https://res.cloudinary.com/dthskrjhy/image/upload/v1629129204/Arc_Medical/One-Circuit-per-Day-2021.pdf" target="_blank" rel="noreferrer">Download Brochure/Specifications</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  )
}

export default IndexPage